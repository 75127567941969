var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'btn',
    {
      btn__secondary: _vm.secondary,
      btn__primary_bg: _vm.primaryBg,
      btn__secondary_bg: _vm.secondaryBg,
      btn__loading: _vm.isLoading,
    } ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('clickCallback')}}},[_c('transition',{attrs:{"name":"v-btn-label","mode":"out-in"}},[(_vm.isLoading)?_c('span',{staticClass:"spinner"}):_c('span',[_vm._v(_vm._s(_vm.title))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }