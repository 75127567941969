import { render, staticRenderFns } from "./VDropdown.vue?vue&type=template&id=66a17854&scoped=true&"
import script from "./VDropdown.vue?vue&type=script&lang=js&"
export * from "./VDropdown.vue?vue&type=script&lang=js&"
import style0 from "./VDropdown.vue?vue&type=style&index=0&id=66a17854&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66a17854",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
