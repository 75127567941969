<template>
  <div class="operator">
    <v-header :operatorName="getOperatorName"/>
    <v-operator-cabinet v-if="getSub"/>
  </div>
</template>

<script>
import VHeader from "@/components/NewVersionComponents/Header/v-header";
import VOperatorCabinet from "@/components/OperatorCabinet/components/VOperatorCabinet";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "OperatorCabinetPage",
  components: {VOperatorCabinet, VHeader},
  async created() {
    await this.setApi(this.$route.params.token)
    await this.setOperatorInfo()
    await this.setOperatorsItems()
  },
  computed: {
    ...mapGetters("operatorCabinet", ["getOperatorId", "getOperatorItems", "getOperatorName", "getSub"])
  },
  methods: {
    ...mapActions("operatorCabinet", ["getAutocompleteInputs", "setOperatorInfo", "setApi", "setOperatorsItems"]),

    save(model, data, item) {
      switch (model) {
        case "aircraftMenu":
          this.aircraftId = item.Id;
          this.aircraftType = item.Type;
          break;
        case "aircraftClassMenu":
          this.AircraftClassId = item.Id;
          this.aircraftClass = item.Name;
          break;
        default:
          break;
      }
    },

    parseUtc(date, time) {
      let [YearForm, MonthForm, DayForm] = this.parseDate(date).split("-");
      let [HoursForm, MinutsForm] = time.split(":");
      let newDate = new Date(
          YearForm,
          MonthForm - 1,
          DayForm,
          HoursForm,
          MinutsForm
      )
          .toISOString()
          .replace(".000Z", "Z");

      return newDate;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
}
</script>

<style scoped lang="scss">

.operator {
  font-family: "Montserrat", sans-serif;
  line-height: 1.34;
  padding: 0 40px;
}
</style>
