<template>
  <button
      :class="[
      'btn',
      {
        btn__secondary: secondary,
        btn__primary_bg: primaryBg,
        btn__secondary_bg: secondaryBg,
        btn__loading: isLoading,
      },
    ]"
      :disabled="disabled"
      @click="$emit('clickCallback')"
  >
    <transition name="v-btn-label" mode="out-in">
      <span v-if="isLoading" class="spinner"/>
      <span v-else>{{ title }}</span>
    </transition>
  </button>
</template>

<script>

export default {
  name: 'VButton',
  props: {
    title: {type: String},
    isLoading: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    primaryBg: {type: Boolean, default: false},
    secondary: {type: Boolean, default: false},
    secondaryBg: {type: Boolean, default: false},
  },
}
</script>

<style scoped lang="scss">
.spinner {
  display: inline-block;
  margin: 0 8px;
  border-radius: 50%;
  width: 0.8em;
  height: 0.8em;
  border: 0.215em solid transparent;
  vertical-align: middle;
  font-size: 16px;
  border-top-color: $primaryLighterColor;
  animation: spin 1s cubic-bezier(0.55, 0.15, 0.45, 0.85) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slide-in-down {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.v-btn-label-enter-active {
  animation: slide-in-down 260ms cubic-bezier(0, 0, 0.2, 1);
}

.v-btn-label-leave-active {
  animation: slide-out-down 260ms cubic-bezier(0.4, 0, 1, 1);
}

@keyframes slide-out-down {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  40% {
    opacity: 0.2;
  }

  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}

.btn,
.btn__primary_bg,
.btn__secondary,
.btn__secondary_bg {
  @include flexCenterAll;
  @include body-16-500;
  width: 100%;
  height: 51px;
  min-width: 101px;
  border-radius: 8px;
}

.btn__secondary,
.btn__secondary_bg {
  padding: 15px 12px;
}

.btn {
  background-color: $primaryDarkerColor;
  color: $secondaryLightestColor;

  &:hover {
    background-color: $primaryDarkColor;
  }

  &:active {
    background-color: $primaryDarkestColor;
  }

  &:disabled {
    background-color: $primaryLightestColor;
    color: $primaryLighterColor;
  }
}

.btn__primary_bg {
  color: $primaryDarkerColor;
  background-color: $secondaryLightestColor;

  &:hover {
    z-index: 1;
    background-color: transparent;
    position: relative;

    &::before {
      @include hoverBtn($secondaryLightestColor, 0.9);
    }
  }

  &:active {
    z-index: 1;
    background-color: transparent;
    position: relative;

    &::before {
      @include hoverBtn($secondaryLightestColor, 0.8);
    }
  }

  &:disabled {
    z-index: 1;
    color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
    position: relative;

    &::before {
      @include hoverBtn($primaryLightestColor, 0.2);
    }
  }
}

.btn__secondary {
  background-color: transparent;
  color: $primaryDarkerColor;
  border: 1px solid $primaryDarkerColor;

  &:hover {
    background-color: transparent;
    color: $primaryDarkColor;
    outline: 1px solid $primaryDarkColor;
  }

  &:active {
    background-color: transparent;
    color: $primaryDarkestColor;
    outline: 1px solid $primaryDarkestColor;
  }

  &:disabled {
    background-color: transparent;
    border: 0;
    color: $primaryLighterColor;
    opacity: 0.5;
  }
}

.btn__secondary_bg {
  background-color: rgba(255, 255, 255, 0.1);
  //border: 1px solid $secondaryLightestColor;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  &:active {
    opacity: 0.8;
    background: rgba(255, 255, 255, 0.15);
  }

  &:disabled {
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 0.5;
    color: $secondaryLightestColor;
  }
}
</style>
